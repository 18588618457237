/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../../redux-magic/state-interface";
import { Area, BHK, Price, PricePerUnit } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Chip, useTheme, Link } from "@mui/material";
import PropertyCardConfigAndContact from "./sub-components/PropertyCardConfigAndContact";
import PropertyCardImage from "./sub-components/PropertyCardImage";
import PropertyCardLocation from "./sub-components/PropertyCardLocation";
import PropertyCardPrice from "./sub-components/PropertyCardPrice";
import PropertyCardTitle from "./sub-components/PropertyCardTitle";
import PropertyCardType from "./sub-components/PropertyCardType";

/* Library Function Imports */

import PrepareConfigString from "../../../../lib/PrepareConfigString";
import prepareConfigTitle from "../../../../lib/PrepareConfigTitle";

/* Styled Components */

const PropertyLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const PropertyCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "10px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	height: "29.65rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: "6.65rem",
		height: "9.5rem",
	},
	/* XS breakpoint */
	[theme.breakpoints.up("xs")]: {
		width: "7.75rem",
		height: "9.5rem",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: "8.65rem",
		height: "9.5rem",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: "6.65rem",
		height: "9.5rem",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: "6.95rem",
		height: "9.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: "7.25rem",
		height: "9.5rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: "7.75rem",
		height: "10rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: "8.1rem",
		height: "10rem",
	},
	/*457*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		width: "8.65rem",
		height: "10.25rem",
	},
	/*485*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		width: "9.25rem",
		height: "10.5rem",
	},
	/*512*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		width: "9.8rem",
		height: "11rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: "7.3rem",
		height: "9.75rem",
	},
	/*570*/
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: "7.77rem",
		height: "9.75rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "7.5rem",
		height: "9.75rem",
	},
	/*642*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: "7.95rem",
		height: "9.75rem",
	},
	/*684*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		width: "8.6rem",
		height: "11.5rem",
	},
	/*726*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 126)]: {
		width: "9.25rem",
		height: "11.5rem",
	},
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "9.9rem",
		height: "12rem",
	},
	/*ipad Air - 820*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "10.65rem",
		height: "12.75rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		width: "11.35rem",
		height: "13.75rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		width: "12.15rem",
		height: "14.75rem",
	},
	/*960*/
	[theme.breakpoints.up("md")]: {
		width: "12.4rem",
		height: "14.75rem",
	},
	/*iPadPro - 1024*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "13.3rem",
		height: "15.5rem",
	},
	/*1107*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		width: "14.5rem",
		height: "15.75rem",
	},
	/*1190 ~~Desktop Layout Start~~*/
	[theme.breakpoints.up("md1190")]: {
		width: "15.65rem",
		height: "16.5rem",
	},
	/*720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "12.45rem",
		height: "15.5rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "13.2rem",
		height: "15.5rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "12.8rem",
		height: "15.5rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "29.65rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "0.35rem 0.35rem 0.35rem 0.35rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const CustomChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "transaction_type",
})<{ transaction_type: string }>(({ theme, transaction_type }) => ({
	height: "1.125rem",
	padding: "0rem",
	// fontWeight: 500,
	fontSize: "0.625rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	backgroundColor:
		transaction_type === "buy"
			? "#b7efc5"
			: transaction_type === "rent"
				? "#ffc6ac"
				: transaction_type === "resale"
					? "#caf0f8"
					: "#b7efc5",
	color:
		transaction_type === "buy"
			? "#1e4620"
			: transaction_type === "rent"
				? "#4c2708"
				: transaction_type === "resale"
					? "#212121"
					: "#1e4620",
}));

const PropertyCard = ({
	id,
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	transaction_type,
	property_type,
	title,
	images,
	property_status,
	possession_date,
	location,
	developer,
	price,
	price_per_unit,
	area,
	bhk,
	floors,
	no_of_seats,
	saved_content,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
	show_contact_details,
	source,
	search_log_id,
}: {
	id?: string;
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	transaction_type: string;
	property_type: Array<string>;
	title: string;
	images: string;
	property_status: Array<string>;
	possession_date: string;
	location: string;
	developer: string;
	price: Price;
	price_per_unit: PricePerUnit;
	area: Area;
	bhk: BHK;
	floors: BHK;
	no_of_seats: BHK;
	saved_content: Array<any>;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean;
	boosting: StateInterface["property"]["boost_information"];
	preview?: boolean;
	show_contact_details: boolean;
	source?: string;
	search_log_id?: string;
}) => {
	const theme = useTheme();

	let BoostingCheck = boosting?.active ? true : false;

	return (
		<React.Fragment>
			<PropertyLink
				href={`/properties/${slug}?search_log_id=${search_log_id}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
				title={title}
			>
				<PropertyCardContainer
					source={source ? source : ""}
					id={id}
				>
					{/* Image */}

					<PropertyCardImage
						_id={_id}
						slug={slug}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						created_by={created_by}
						assigned_to={assigned_to}
						isEditor={isEditor}
						boosting={boosting}
						transaction_type={transaction_type}
					/>

					{/* Title */}

					<PropertyCardInfoContainer>
						<PriceAndTransactionContainer>
							{/* Price */}

							<PropertyCardPrice
								loading={loading}
								price={price}
							/>

							{/* Transaction Type Chip */}

							{/* <CustomChip
								label={
									transaction_type && transaction_type === "buy"
										? "Sale"
										: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
								}
								transaction_type={transaction_type}
							/> */}
						</PriceAndTransactionContainer>

						<PropertyCardTitle
							loading={loading}
							title={title !== "" || undefined ? title : "Unknown Title"}
						/>

						{/* Location */}

						<PropertyCardLocation
							loading={loading}
							location={location !== "" || undefined ? location : "Unknown Location"}
						/>

						{/* Property Type */}

						<PropertyCardType
							loading={loading}
							propertyType={property_type ? property_type : []}
						/>

						{/* Config and CTA */}

						<PropertyCardConfigAndContact
							loading={loading}
							developer={developer !== "" || undefined ? developer : created_by.business_profile_id.title}
							config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
							card_title={prepareConfigTitle(transaction_type, property_type)}
							dispatch={dispatch}
							session={session}
							slug={slug}
							title={title}
							created_by={created_by}
							show_contact_details={show_contact_details}
						/>
					</PropertyCardInfoContainer>
				</PropertyCardContainer>
			</PropertyLink>
		</React.Fragment>
	);
};

export default PropertyCard;
